import * as React from "react";
import "../styles/footer.css";

export default function Footer({ animation, company_licence, company_mail }) {
  return animation ? (
    <footer className={animation ? "dangle" : null}>
      <div>{company_licence}</div>
      <div>e-mail: {company_mail}</div>
    </footer>
  ) : null;
}
