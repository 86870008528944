import * as React from "react";
import { useState, useEffect } from "react";
import Footer from "./footer";
import "../styles/style.css";
import "../styles/common_style.css";
import Lottie from "lottie-react-web";
import lottieAnimation from "../images/rocket_background_animation.json";
import { Helmet } from "react-helmet";
import { Fragment } from "react";
import { graphql } from "gatsby";

export default function Component({ data }) {
  // const company_logo = logo;
  const [animation, setAnimation] = useState(false);
  useEffect((animation) => {
    setAnimation(true);
  });

  const company_logo = data.prismicLandingPage.dataRaw.company_logo.url;
  const linkList = data.prismicLandingPage.dataRaw.subdomain_links;
  const company_licence = data.prismicLandingPage.dataRaw.license;
  const company_mail = data.prismicLandingPage.dataRaw.mail;

  return animation ? (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Artcraft.uz</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="description" content="arctaft" />
      </Helmet>
      <section className="html-body">
        <div className="company-branches">
          <div className="parent">
            <img
              className={
                animation ? "company-logo-img dangle" : "company-logo-img"
              }
              src={company_logo}
              alt=""
            />
            <div
              className={
                animation
                  ? "background-animation dangle"
                  : "background-animation"
              }
            >
              <Lottie
                options={{
                  animationData: lottieAnimation,
                }}
              />
            </div>
          </div>

          <ul className={animation ? "dangle" : null}>
            {linkList.map((company, index) => (
              <li key={index}>
                <a href={company.subdomain_link.url}>
                  {company.subdomain_name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Footer
          animation={animation}
          company_licence={company_licence}
          company_mail={company_mail}
        />
      </section>
    </Fragment>
  ) : null;
}

export const query = graphql`
  {
    prismicLandingPage {
      dataRaw
    }
  }
`;
